<template>
  <template v-if="$platform.environment === 'development'">
    <be-dropdown-form v-if="compact" form-class="p-1">
      <be-form-select
        :options="options"
        required
        :searchable-threshold="-1"
        :popper-opts="{ placement: 'left-start' }"
        @change="onChange"
        @search="search"
      />
    </be-dropdown-form>

    <template v-else>
      <div class="my-3 d-flex flex-column gap-1 border rounded p-3 bg-light">
        <h3>Dev login</h3>

        <be-form-group :label="$t('activerecord.models.user.one')">
          <be-form-select
            v-model="email"
            :options="options"
            required
            :searchable-threshold="0"
            @search="search"
          />
        </be-form-group>

        <template v-if="!manage">
          <be-form-group label="IDP">
            <be-form-select
              v-model="idp"
              :options="idpOptions"
              include-blank-option="No IDP"
            />
          </be-form-group>

          <be-form-checkbox v-model="mfa" class="mb-3">MFA</be-form-checkbox>
        </template>

        <be-button @click="login">{{ $t("login.login") }}</be-button>
      </div>
    </template>
  </template>
</template>

<script>
export default {
  props: {
    compact: {
      type: Boolean,
      default: false,
    },

    manage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: [],
      email: "",
      mfa: true,
      idp: "",
    };
  },

  computed: {
    idpOptions() {
      return [
        { value: "riksb", label: "Riksbyggen" },
        { value: "fortnox", label: "Fortnox" },
      ];
    },
  },

  watch: {
    "$platform.environment": {
      immediate: true,

      handler(value) {
        if (value === "development") {
          this.search("");
        }
      },
    },
  },

  mounted() {
    if (this.$platform.environment === "development") {
      this.search("");
    }
  },

  methods: {
    login() {
      const params = new URLSearchParams();
      params.append("email", this.email);
      params.append("mfa", this.mfa);
      params.append("idp", this.idp);
      params.append("manage", this.manage);

      window.location.href = `/development/session/new?${params.toString()}`;
    },

    onChange(value) {
      window.location.href = `/development/session/new?email=${value}&mfa=true`;
    },

    async search(value) {
      try {
        const response = await axios.get(
          `/development/session?email=${value}&manage=${this.manage}`
        );

        this.options = response.data.map((user) => ({
          value: user.email,
          label: user.email,
        }));
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
